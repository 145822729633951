#RecipeView .rteContent ol {
  padding-left: 12px;
}
#RecipeView .rteContent ul {
  padding-left: 18px;
}
#RecipeView .rteContent a {
  color: #62a5a1;
  text-decoration: none;
}
#RecipeView .rteContent a:hover {
  text-decoration: underline;
}

#RecipeView ol {
  list-style: none;
  counter-reset: li;
  margin-left: 4px;
}
#RecipeView ol > li::before {
  content: counter(li);
  color: #62a5a1;
  border-radius: 50%;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  counter-increment: li;
  font-weight: 600;

  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}
#RecipeView ul {
  list-style: none;
}
#RecipeView ul > li {
  padding-left: 0;
  position: relative;
}
#RecipeView ul > li > p {
  display: inline-block;
}

#RecipeView ul > li::before {
  content: "\25AA";
  color: #62a5a1;
  display: inline-block;
  width: 1em;
  margin-left: -1em;

  position: absolute;
  top: 2px;
}
